// ** React Imports
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

// Custom Components
import Transaction from '../components/Transaction'
import FallbackSpinner from 'core/components/spinner'

// ** Icon Imports
import CalendarIcon from 'mdi-material-ui/Calendar'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/billing'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  paddingLeft: 3,
  paddingRight: 3
}))

// Styled Card content
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingLeft: '15%',
  paddingRight: '15%',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '15%',
    paddingRight: '15%'
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

// Format Complexe Date

const TransactionsList = () => {
  // ** Hooks
  const navigate = useNavigate()

  // ** Selectors
  const { customerInformation, customerInvoices, loading } = useSelector(customersSelector)

  // ** State
  const [active, setActive] = useState(null)

  // ** Constants
  const sortedInvoices =
    customerInvoices?.length > 0
      ? [...customerInvoices]?.sort((a, b) => {
          // Compare by startDate in descending order
          if (new Date(b.created_at) > new Date(a.created_at)) {
            return 1
          } else if (new Date(a.created_at) - new Date(b.created_at)) {
            return -1
          } else {
            // If startDate values are the same, compare by ID in ascending order
            return b.id - a.id
          }
        })
      : []

  // ** Functions

  return (
    <StyledCard>
      <CardHeader
        sx={{
          mx: '-1.5rem'
        }}
        // title={
        //   <Box
        //     sx={{
        //       display: 'flex',
        //       mt: '-2rem',
        //       py: '1rem',
        //       px: '3rem',
        //       boxShadow: ' 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
        //       display: 'flex',
        //       justifyContent: 'space-between'
        //     }}
        //   >
        //     <Box display='flex'>
        //       <Typography
        //         variant='h6'
        //         sx={{ fontWeight: 600, color: 'text.secondary', cursor: 'pointer' }}
        //         onClick={() => navigate('/billing')}
        //       >
        //         Payments
        //       </Typography>

        //       <Typography variant='h6' sx={{ fontWeight: 600, color: 'text.secondary' }}>
        //         &nbsp; {'>'} &nbsp;Invoices
        //       </Typography>
        //       <Typography variant='h6' sx={{ fontWeight: 600, color: 'text.secondary' }}>
        //         &nbsp; {'>'} &nbsp;{customerInformation?.organization}
        //       </Typography>
        //     </Box>
        //   </Box>
        // }
      />
      <StyledCardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mb: '1rem' }}></Grid>
          {loading !== 'GET_INVOICES'
            ? sortedInvoices?.map(invoice => (
                <Grid key={invoice.id} item xs={12} sx={{ mb: '0.5rem' }}>
                  <Transaction invoice={invoice} active={active} setActive={setActive} />
                </Grid>
              ))
            : null}
          <Grid item xs={12} sx={{ mt: '2rem' }}>
            {loading === 'GET_INVOICES' ? <FallbackSpinner /> : null}
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  )
}

export default TransactionsList
