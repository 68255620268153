// CUSTOMERS
export const GET_BOARDED_CUSTOMER = 'customers/findOneByDomain'

// ** METADATA
export const CRUD_METDATA = 'metadata/'

// Invoices
export const GET_CUSTOMER_INVOICES = `customers/getCustomerInvoices`
export const GET_CUSTOMER_INVOICE = `customers/getCustomerInvoice`
export const GET_DAILYUSAGE_INVOICE = 'dailyUsage/invoice/'
export const GET_GCP_USAGE = 'dailyUsage/gcpUsage/'
