import { cloudGet, get } from './api_setup'
import * as url from './url_helper'
import { SignJWT } from 'jose'

const generateJWTToken = async data => {
  const secret = new TextEncoder().encode('s3cR3TB1ll1n9')
  const jwt = await new SignJWT(data)
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime('2h')
    .sign(secret)

  return jwt
}

const getBoardedCustomerApi = async domain =>
  get(url.GET_BOARDED_CUSTOMER, { headers: { token: await generateJWTToken({ domain }) } })

// ** METADATA
const getMetaDataData = type => get(url.CRUD_METDATA + type)

// ** Invoices
const getInvoices = async customerId =>
  get(url.GET_CUSTOMER_INVOICES, { headers: { token: await generateJWTToken({ customerId }) } })
const getInvoice = async (customerId, invoiceId) =>
  get(url.GET_CUSTOMER_INVOICE, { headers: { token: await generateJWTToken({ customerId, invoiceId }) } })
const getDailyUsageInvoice = id => get(url.GET_DAILYUSAGE_INVOICE + id)
const getGcpUsage = invoiceId => get(url.GET_GCP_USAGE + invoiceId)

export { getBoardedCustomerApi, getInvoices, getDailyUsageInvoice, getMetaDataData, getInvoice, getGcpUsage }
