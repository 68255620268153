// ** React Imports
import React from 'react'

// ** MUI Imports
import SvgIcon from '@mui/material/SvgIcon'

// ** Icon imports
import AccountOutline from 'mdi-material-ui/AccountOutline'
import DirectoryOutline from 'mdi-material-ui/ClipboardAccountOutline'
import ToolsOutline from 'mdi-material-ui/WrenchOutline'
import BillingCard from 'mdi-material-ui/CreditCardOutline'

import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'
import { useAuth } from 'hooks/useAuth'

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d='M19 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 2v13H5V4h14zM5 20v-1h14v1H5zm6-11.5c0 .83-.67 1.5-1.5 1.5S8 9.33 8 8.5 8.67 7 9.5 7s1.5.67 1.5 1.5zm5 0c0 .83-.67 1.5-1.5 1.5S13 9.33 13 8.5 13.67 7 14.5 7s1.5.67 1.5 1.5zM9.5 11c-1.16 0-3.5.55-3.5 1.65V14h7v-1.35c0-1.1-2.34-1.65-3.5-1.65zm8.5 1.65V14h-4v-1.35c0-.62-.3-1.12-.75-1.5.46-.1.9-.15 1.25-.15 1.16 0 3.5.55 3.5 1.65z'></path>
    </SvgIcon>
  )
}

const navigation = () => {
  // ** Hooks
  const auth = useAuth()

  // ** Constants
  const requiredDomain = 'premiercloudsolution'
  const domain = auth.user?.primaryEmail?.split('@')?.[1]

  return [
    {
      title: 'Users',
      icon: AccountOutline,
      path: '/user-management',
      children: [
        {
          title: 'User Management',
          path: '/user-management'
        }
      ]
    },
    {
      title: 'Directory',
      icon: DirectoryOutline,
      path: '/groups-management',
      children: [
        {
          title: 'Groups',
          path: '/groups-management'
        },
        {
          title: 'Group Template',
          path: '/groups-template'
        }
      ]
    },
    {
      title: 'Tools',
      icon: ToolsOutline,
      path: '/tools/',
      children: [
        {
          openInNewTab: true,
          title: 'Signature Template',
          path: '/tools/templates'
        },
        {
          openInNewTab: true,
          title: 'Bulk Signature Update',
          path: '/tools/bulk-signatures'
        }
      ]
    },
    {
      title: 'Shared Drive',
      icon: HomeIcon,
      path: '/shared-drive',
      children: [
        {
          title: 'Shared Drive',
          path: '/shared-drive'
        }
      ]
    },
    ...(domain?.includes(requiredDomain)
      ? [
          {
            title: 'Billing',
            icon: BillingCard,
            path: '/billing'
          }
        ]
      : []),
    ...(auth.user?.supportCaseAccess
      ? [
          {
            title: 'Support Case',
            icon: SupportAgentOutlinedIcon,
            path: '/support-case',
            children: [
              {
                title: 'All cases',
                path: '/support-case/all'
              }
            ]
          }
        ]
      : [])
  ]
}

export default navigation
