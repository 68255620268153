// ** Axios
import axios from 'axios'

import { createSlice } from '@reduxjs/toolkit'

// ** API Imports
import { getMetaDataData } from 'configs/ba-axios/api_helper'

export const initialState = {
  loading: false,
  currenciesData: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const currencySlice = createSlice({
  name: 'currencyData',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getCurrencyDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.currenciesData = data
      state.loading = false
      state.pagination = filters
      state.success = 'GET'
    },
    getCurrencyDataFailure: state => {
      state.loading = false
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addCurrency,
  updateCurrency,
  removeCurrency,
  startLoading,
  resetMessages,
  getCurrencyDataFailure,
  getCurrencyDataSuccess
} = currencySlice.actions

// export user selector to get the slice in any component
export const currencySelector = state => state.currenciesData

// export The reducer
const currenciesReducer = currencySlice.reducer
export default currenciesReducer

// Actions
export const fetchCurrencyData = () => async dispatch => {
  try {
    dispatch(startLoading('GET'))
    const { data } = await getMetaDataData('type/currency')
    dispatch(getCurrencyDataSuccess(data))
  } catch (error) {
    dispatch(getCurrencyDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
