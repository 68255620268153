// ** React Imports
import React, { useEffect } from 'react'

// ** MUI Imports
import { Typography, Card, CardHeader, Divider, CircularProgress, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

// ** Demo Components Imports
import TransactionsList from './containers/TransactionsList'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerInvoices, getBoardedCustomerInformationOnly, customersSelector } from 'store/billing'
import { useAuth } from 'hooks/useAuth'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const Customers = () => {
  // ** Hooks
  const { user } = useAuth()
  const dispatch = useDispatch()

  // ** Selectors
  const { customerInformation, loading } = useSelector(customersSelector)

  // ** Fetch customers
  useEffect(() => {
    if (!!user) {
      dispatch(getBoardedCustomerInformationOnly(user?.primaryEmail?.split('@')?.[1]))
    }
  }, [])

  useEffect(() => {
    if (customerInformation?.id) {
      dispatch(getCustomerInvoices(customerInformation?.id))
    }
  }, [customerInformation])

  return (
    <Card
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        }
      }}
    >
      <CardHeader sx={{ mb: '-1rem' }} title={<Typography>Billing</Typography>} />
      <StyledDivider />

      {loading !== 'GET' && <TransactionsList />}
      {loading === 'GET' && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <CircularProgress disableShrink sx={{ mt: 6 }} />
        </Box>
      )}
    </Card>
  )
}

export default Customers
