// ** React Imports
import React, { useState, useEffect } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

// ** 3rd party Libraries
import moment from 'moment'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, getCustomerInvoice } from 'store/billing'

// ** Custom Components
import TransactionsTable from './TransactionsTable'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: '-0.5rem',
  borderBottomWidth: 2,
  color: theme.palette.grey[300]
}))

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: '0.5rem',
  border: `solid 1px ${theme.palette.grey[300]}`,
  paddingLeft: 3,
  paddingRight: 3,
  '&:hover': {
    boxShadow: theme.shadows[8]
  }
}))

const Transaction = props => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Props
  const { invoice, active, setActive } = props

  // ** State
  const [collapsed, setCollapsed] = useState(false)
  const [inAdvanceFormattedTable, setInAdvanceFormattedTable] = useState([])

  // ** Selectors
  const { invoiceInformation, success, error, loading, customerInformation } = useSelector(customersSelector)

  // ** Fetch customer invoice
  useEffect(() => {
    if (active !== invoice?.id) setCollapsed(false)
  }, [active])

  // ** Functions
  const renderTransactionDate = () => {
    const start = moment(invoice?.issue_date, 'YYYY-MM-DD')
    const end = moment(invoice?.due_date, 'YYYY-MM-DD')
    if (start.format('Y') !== end.format('Y')) {
      return moment(start).format('MMM DD, YYYY') + ' - ' + moment(end).format('MMM DD, YYYY')
    } else if (start.format('D') == end.format('D') && start.format('M') == end.format('M')) {
      return moment(start).format('MMM DD, YYYY')
    } else if (start.format('D') != end.format('D') && start.format('M') == end.format('M')) {
      return moment(start).format('MMM DD') + ' - ' + moment(end).format('DD, YYYY')
    } else if (start.format('M') != end.format('M')) {
      return moment(start).format('MMM DD') + ' - ' + moment(end).format('MMM DD, YYYY')
    } else return ''
  }

  const openCardHandler = () => {
    setActive(collapsed ? null : invoice?.id)
    setCollapsed(!collapsed)
    if (!collapsed && invoice?.id !== invoiceInformation?.id)
      dispatch(
        getCustomerInvoice({
          customerId: customerInformation.id,
          invoiceId: invoice?.id
        })
      )
  }

  return (
    <StyledCard
      sx={{
        position: 'relative',
        ...(collapsed && {
          border: 0,
          '&:hover': {
            boxShadow: 0
          }
        })
      }}
    >
      <CardHeader
        sx={{
          cursor: 'pointer'
        }}
        onClick={openCardHandler}
        title={
          !collapsed ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.875rem' }}>{renderTransactionDate()}</Typography>
            </Box>
          ) : (
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Box display='flex' alignItems='center' gap={2}>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: 550 }}>{renderTransactionDate()}</Typography>
              </Box>
            </Box>
          )
        }
        action={
          !collapsed && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.825rem' }}>
                {invoiceInformation?.id === invoice.id ? invoiceInformation?.status : invoice?.status}
              </Typography>
            </Box>
          )
        }
      />
      <Collapse in={collapsed} open={collapsed}>
        <StyledDivider />
        <Grid container spacing={2}>
          {invoiceInformation?.items?.length > 0 && (
            <TransactionsTable
              invoiceDetails={invoiceInformation?.items}
              type={invoiceInformation?.type}
              issueDate={invoiceInformation?.issue_date}
              setInAdvanceFormattedTable={setInAdvanceFormattedTable}
            />
          )}
          {invoiceInformation?.items?.length < 1 && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography>There is no subscriptions</Typography>
            </Grid>
          )}
        </Grid>
        <Backdrop
          open={loading === 'GET_INVOICE' && invoiceInformation?.id !== invoice?.id}
          sx={{
            position: 'absolute',
            backgroundColor: 'transparent',
            backdropFilter: 'blur(5px)',
            color: theme => theme.palette.common.white,
            zIndex: theme => theme.zIndex.mobileStepper - 1
          }}
        >
          <CircularProgress color='primary' size='4rem' />
        </Backdrop>
      </Collapse>
    </StyledCard>
  )
}

export default Transaction
